import * as React from "react"
import { ThemeProvider } from "@mui/material"
import { CssBaseline } from "@mui/material"

import TopBar from "../components/TopBar"
import Footer from "../components/Footer"
import lightTheme from "../themes/light"
import HomeTextContainer from "../components/HomeTextContainer"
import SmallHero from "../components/SmallHero"

import { graphql } from "gatsby"

const PRPage = ({data}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <title>ITS - Ponti Radio</title>
      <CssBaseline />
      <TopBar  />
      <SmallHero 
        title="Ponti Radio"
        description="Possiamo installare collegamenti ADSL-HDSL siano questi economici o estrememante professionali e performanti a Banda davvero larga, a prezzi davvero concorrenziali."
        href="servizi"
        image="https://www.us-consulting.it/img/vds.jpg"
        hideButton={true}
        />

        <HomeTextContainer 
        title="ITS Telecomunicazioni //" 
        subtitle={data.markdownRemark.frontmatter.title} 
        hideIcon={true}
        hideButton={true}
        description={ data.markdownRemark.html }
        noBorder/>

      <Footer />
    </ThemeProvider>
  )
}

export default PRPage

export const contentQuery = graphql`
{
  markdownRemark(fileAbsolutePath: { regex: "/pontiradio.md/"}) {
    frontmatter {
			title
    }
    html
  }
}
`